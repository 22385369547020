import React, { useState } from 'react';
import clsx from 'clsx';
import Feedback from 'components/feedback/container';
import Layout from './components/layout';
import styles from './git.module.css';

import { meta } from 'metaData';

function GetInTouch() {
  const [isOpen, setOpen] = useState<boolean>(true);

  return (
    <Layout isOpen={isOpen} metaData={meta.getInTouch}>
      <section className={clsx('section', styles.sendMessage)}>
        <div className={styles.wrapper}>
          <Feedback
            onChange={(bool) => {
              setOpen(bool);
            }}
            defaultIndex={1}
          />
        </div>
      </section>
    </Layout>
  );
}

export default GetInTouch;
