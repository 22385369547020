import * as React from 'react';
import Person from '../person';
import usePrimaryContact from 'hooks/usePrimaryContact';

function PersonAC() {
  const { avatar, full_name, position } = usePrimaryContact();
  return <Person title={position} name={full_name} ava={avatar.childImageSharp.fluid} />;
}

export default PersonAC;
