import * as React from 'react';
import clsx from 'clsx';
import Layout from 'components/layout';
import Footer from 'components/layout/footer';
import Person from 'components/feedback/ac';
import Header from './header';
import styles from './layout.module.css';
import { IMainMeta } from 'components/seo';

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  metaData: IMainMeta;
}

const GITLayout = ({ children, isOpen, metaData }: Props) => (
  <Layout isRed navBtnTextColor="#14181A" metaData={{ main: metaData }}>
    <Header>
      <div className={styles.column}>
        <h1 className={styles.title}>Tell us about your project</h1>
        <p className={styles.subtitle}>
          Have a revolutionary business idea? <br />
          Let’s hit the market together!
        </p>
      </div>
      <div className={clsx(styles.person, { [styles.open]: isOpen })}>
        <Person />
      </div>
    </Header>
    <main className={clsx('main', styles.main)}>{children}</main>
    <Footer />
  </Layout>
);

export default GITLayout;
